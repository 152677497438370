<template>
  <div>
    <div class="layout-main-body">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">销售额</div>
            <div class="user-card-number">{{ '¥ ' + reportTotal.totalAmount }}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">总用户数</div>
            <div class="user-card-number">{{ reportTotal.total }}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">普通用户数</div>
            <div class="user-card-number">{{ reportTotal.totalOrdinary }}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">VIP（一年）</div>
            <div class="user-card-number">{{ reportTotal.totalV1 }}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">VIP（三年）</div>
            <div class="user-card-number">{{ reportTotal.totalV3 }}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card class="user-card" shadow="never">
            <div class="user-card-title">即将到期（三个月）</div>
            <div class="user-card-number">{{ reportTotal.totalExpire }}</div>
          </el-card>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col>
          <sales-result-card data-type="user"></sales-result-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import { RptApi } from '@/api'
import numeral from 'numeral'
import SalesResultCard from '@/views/dashboard/components/SalesResultCard'

const defaultReportTotal = () => {
  return {
    total: 0,
    totalVip: 0,
    totalOrdinary: 0,
    totalV1: 0,
    totalV3: 0,
    totalExpire: 0,
    totalAmount: 0
  }
}

export default {
  name: 'Overview',
  components: {
    SalesResultCard
  },
  data () {
    return {
      reportTotal: defaultReportTotal()
    }
  },
  methods: {
    getTotalUser () {
      RptApi.getUserCountTotalReport({ orgId: this.$store.getters.orgId }).then((res) => {
        this.reportTotal = res || defaultReportTotal()
        this.reportTotal.totalAmount = numeral(this.reportTotal.totalAmount).format('0,0')
      })
    }
  },
  created () {
    this.getTotalUser()
  }
}
</script>

<style lang="less">
.user-card {
  .user-card-title {
    height: 22px;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }

  .user-card-number {
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>
